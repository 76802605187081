import React, { useState } from "react"
// import ReactDOM from "react-dom"
import Modal from "react-modal"
// import Popup from "../../Popup/Popup"

Modal.setAppElement("#___gatsby")

const Example = ({ id, image, title, tags, html }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen)
  }

  return (
    <>
      <article className={`example`} onClick={togglePopup}>
        <div className="example__image-container">
          <img className="example__image" src={image} alt="" />
        </div>
        <header className="example__header">
          <h4 className="example__title">{title}</h4>
        </header>
      </article>
      <Modal
        isOpen={isPopupOpen}
        id={`ReactModal-${id}`}
        onRequestClose={togglePopup}
        style={{}}
        // className="example-modal"
        // bodyOpenClassName="hasOpenModal"
        // portalClassName="example-modal"
        // overlayClassName="example-modal__overlay"
        contentLabel={"Example Popup"}
      >
        <header className="ReactModal__header">
          <h3 className="ReactModal__title">{title}</h3>
          <div className="ReactModal__tags">
            {tags.map((tag, i) => (
              <span key={i}>{tag}</span>
            ))}
          </div>
        </header>
        <div
          className="ReactModal__body"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <footer className="ReactModal__footer">
          <button
            type="button"
            className="ReactModal__close"
            onClick={togglePopup}
          >
            Close
          </button>
        </footer>
      </Modal>
    </>
  )
}

export default Example
