import React, { useCallback, useState } from "react"
import uniq from "lodash/uniq"
import flattenDeep from "lodash/flattenDeep"
import filter from "lodash/filter"
import includes from "lodash/includes"
// import sortBy from "lodash/sortBy"
// import intersection from "lodash/intersection"
// import pull from "lodash/pull"
import Example from "./Example"
// import "./ExampleGroup.scss"

const ExampleGroup = ({ examples }) => {
  const tags = useCallback(() => {
    const tagsArray = []
    examples.map((example) => tagsArray.push(example.node.frontmatter.tags))
    return uniq(flattenDeep(tagsArray))
  }, [examples])
  const [currentFilter, setCurrentFilter] = useState(["All"])
  const [filteredExamples, setFilteredExamples] = useState(examples)

  const handleFilter = (f) => {
    const filteredExamples = filter(examples, (e) => {
      const { tags } = e.node.frontmatter
      return tags.includes(f)
    })

    if (f === "All") {
      setCurrentFilter(["All"])
      setFilteredExamples(examples)
      return
    }

    if (currentFilter.includes(f)) {
      const updatedFilter = currentFilter.filter((t) => t !== f || f === "All")
      setCurrentFilter(updatedFilter)
      setFilteredExamples(filteredExamples)
      return
    }

    if (f !== "All") {
      const updatedFilter = currentFilter.filter((f) => f !== "All")
      setCurrentFilter([...updatedFilter, f])
      setFilteredExamples(filteredExamples)
      return
    }

    return
  }

  return (
    <section className="examples">
      <div className="filters examples__filters">
        <button
          className={`filters__btn ${
            includes(currentFilter, "All") ? `filters__btn--selected` : ``
          }`}
          onClick={() => handleFilter("All")}
        >
          All
        </button>
        {tags().map((tag, i) => (
          <button
            key={i}
            className={`filters__btn ${
              includes(currentFilter, tag) ? `filters__btn--selected` : ``
            }`}
            onClick={() => handleFilter(tag)}
          >
            {tag}
          </button>
        ))}
      </div>
      <div className="examples__grid">
        {examples.map((example) => {
          const {
            node: {
              html,
              frontmatter: { id, category, title, tags, image },
            },
          } = example
          return (
            <Example
              key={id}
              id={id}
              category={category}
              title={title}
              tags={tags}
              image={image}
              html={html}
            />
          )
        })}
      </div>
    </section>
  )
}

export default ExampleGroup
