import React, { useCallback } from "react"
import SEO from "../components/seo"
import Layout from "../layouts/default"
// import "../scss/demo.scss"
import { graphql } from "gatsby"
import uniq from "lodash/uniq"
import groupBy from "lodash/groupBy"
import sortBy from "lodash/sortBy"
import ExampleGroup from "../components/ExampleGroup"

const DemoPage = (data) => {
  const {
    allMarkdownRemark: { edges: examples },
  } = data.data

  const mainCategories = useCallback(() => {
    const cats = []

    examples.map((example) => cats.push(example.node.frontmatter.category))

    return uniq(cats)
  }, [examples])

  const groups = groupBy(examples, "node.frontmatter.category")

  return (
    <>
      <Layout>
        <SEO title="Learn Surface 3D Modeling" description="Tips and Tricks for 3D Models and 3D Design on Cloud" />
        <main className="page page--learn">
          <div className="container">
            {Object.entries(groups).map(([key, values], i) => {
              return (
                <section
                  className={`section section--${key.toLowerCase()}`}
                  key={i}
                >
                  <header className="section__header">
                    <h2 className="section__title">{key}</h2>
                  </header>
                  <ExampleGroup examples={groups[key]} />
                </section>
              )
            })}
          </div>
        </main>
      </Layout>
    </>
  )
}

export default DemoPage

export const pageQuery = graphql`
  query exampleQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(examples)/" } }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            id
            category
            title
            tags
            image
          }
          html
        }
      }
    }
  }
`
